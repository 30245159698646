.main {
  width: 100vw;
  height: 100vh;
  background: url("./images/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 30%;
  min-width: 300px;
  max-width: 800px;
}
.frame {
  position: absolute;
  top: -10px;
  width: 100%;
  max-width: 530px;
}
.text {
  margin-top: 70px;
  position: relative;
  color: #ffffff;;
  font-size: 28px;
  font-family: 'Play', sans-serif;
  font-weight: bold;
  text-shadow: 0px 0px 4px #FF8200;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

